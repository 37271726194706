<template>
  <section style="text-transform: uppercase;">
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="row">
      <div class="col-md-10" v-if="this.turma_ativa">
        <h5 class="cabecario-turma"
            style="background-color: #3B82F6;
                  margin-bottom: -25px;
                  margin-top: 21px !important;
                  width: 186px;">
            Turma Habilitada
        </h5>
      </div>
      <div class="col-md-10" v-else></div>
      <div class="col-md-2" style="margin-top: 6px;">
        <pm-Button type="button" v-if="tipoDeAcao == 0" @click="trocarTurma" icon="pi pi-angle-left"
            label="MUDAR DE TURMA" title="MUDAR DE TURMA"  class="p-button-sm p-button-danger btn-color"
            style="float: right;"
        >
        </pm-Button>

        <pm-Button v-if="tipoDeAcao != 0" type="button" @click="this.$router.push({name: 'turmas'});" icon="pi pi-angle-left"
            label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color"
            style="float: right;"
        >
        </pm-Button>

      </div>
    </div>

    <br>

    <div class="row"  v-if="erroturmaeditar == 1">
      <div class="col-md-12">
        <va-alert color="danger" class="mb-4" style="margin-top:20px">
          Para Editar a turma é necessário Remover os Professores da turma! {{tipoDeAcao}}
        </va-alert>
        <va-button @click="retornarEdicaoTurma()" class="mr-4" color="gray"> Voltar para edição da Turma</va-button>
      </div>
    </div>

    <div  class="card form-elements" style="margin-top:45px; padding:35px;">
      <div class="row">
        <!--Disciplina Professor-->
        <div v-if="tipoDeAcao == 0 || tipoDeAcao == 1" :class="'col-md-'+colAddProfessor" style="padding: 25px; background-color: #fff;border: 1px solid rgba(0, 0, 0, 0.125);border-radius: 0.25rem;box-shadow: 0 4px 70px -18px #70707057;">
          <div class="row">
            <div class="col-md-8">
              <h6 style="text-transform: uppercase;padding-top: 27px;padding-bottom: 13px;color: #697277;" v-if="!ehDoTiPoCreche">Disciplinas e Professores</h6>
              <h6 style="text-transform: uppercase;padding-top: 27px;padding-bottom: 13px;color: #697277;" v-if="ehDoTiPoCreche">Turmas do tipo creche não possuem exibição de disciplinas!</h6>
            </div>
            <div class="col-md-4" style="padding-top: 17px;" v-if="situacaoAno">
              <va-button class="mr-2 mb-2" v-if="disciplina_tem == 0 && !ehDoTiPoCreche" color="success"  @click="abrirModalAdicionar = !abrirModalAdicionar" > {{ $t('ADICIONAR DISCIPLINAS') }}</va-button>
              <va-button class="mr-2 mb-2" v-else @click="abrirModalAdicionar = !abrirModalAdicionar" style="float:right" v-if="!this.turma_ativa && !ehDoTiPoCreche"> {{ $t('EDITAR DISCIPLINAS') }}</va-button>
            </div>
          </div>
          <table class="table" style="background-color: #e1e8ec;">
            <thead style="display: none;">
              <tr>
                <th>Disciplina</th>
                <th>Professores</th>
                <th>acao</th>
              </tr>
            </thead>
            <tbody v-if="disciplinasTurma != 'A Turma Não possui Disciplina!'">
              <tr v-for="(item, index) in disciplinasTurma" v-bind:key="item.id">
                <td style="color:#697277;font-weight: 600;padding: 16px;">{{item.disciplina.nome}}</td>
                <td style="color:#697277;font-weight: 600;padding: 16px;" v-if="item.jatemprofessor != 'Professor não Encontrado!'">{{item.jatemprofessor.nome}}</td>
                <td style="color:#697277;font-weight: 600;padding: 16px;" v-else> Não Possui Professor</td>
                <td v-if="estiloTabela == 2 || (estiloTabela == 3 && !ejaEhFundamental1) && situacaoAno">
                  <va-button v-if="item.jatemprofessor == 'Professor não Encontrado!'" @click="professorDaDisciplina(item.jatemprofessor.id, item.id,item.disciplina.nome,item.jatemprofessor,item.professores, 1)"
                     color="#097e3300"
                  >
                  <i class="pi pi-user-plus"  style="font-size: 24px;color: #697277;"></i>
                  </va-button>

                  <va-button v-if="item.jatemprofessor != 'Professor não Encontrado!'" @click="professorDaDisciplina(item.jatemprofessor.id, item.id,item.disciplina.nome,item.jatemprofessor,item.professores, 2)"
                     color="#097e3300"
                  >
                    <i class="pi pi-user-edit"  style="font-size: 24px;color: #697277;"></i>
                  </va-button>

                  <div v-if="turma_dados[0].ativa == 0">

                    <va-button v-if="item.jatemprofessor != 'Professor não Encontrado!'" @click="deletarDisciplinaNaoFundamentalI(item)"
                        color="#097e3300"
                      >

                      <i class="pi pi-user-minus" style="font-size: 24px;color: #697277;"></i>
                    </va-button>
                  </div>
                </td>
                <!-- Apenas para fundamental 1 e ERN -->
               <td v-if="(estiloTabela == 1 || ehDoTiPoCreche || ejaEhFundamental1) && index == 0 && situacaoAno">                                                                                                                                  <!--1 se for para adicionar-->
                 <va-button  v-if="index == 0 && item.jatemprofessor == 'Professor não Encontrado!'" @click="professorDaDisciplina(item.jatemprofessor.id, item.id,item.disciplina.nome,item.jatemprofessor,item.professores, 1)"
                      color="#097e3300"
                  >
                    <i class="pi pi-user-plus"  style="font-size: 24px;color: #697277;"></i>
                  </va-button>
                  <va-button  v-if="index == 0 && item.jatemprofessor != 'Professor não Encontrado!'" @click="professorDaDisciplina(item.jatemprofessor.id, item.id,item.disciplina.nome,item.jatemprofessor,item.professores, 2)"
                      color="#097e3300"
                  >
                    <i class="pi pi-user-edit"  style="font-size: 24px;color: #697277;"></i>
                  </va-button>

                  <div v-if="turma_dados[0].ativa == 0">
                    <va-button v-if="item.jatemprofessor != 'Professor não Encontrado!'" @click="deletarFundIDisciplina"
                        color="#097e3300"
                      >
                      <i class="pi pi-user-minus"  style="font-size: 24px;color: #697277;"></i>
                    </va-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--Aluno-->
        <div v-if="tipoDeAcao == 0 || tipoDeAcao == 2" :class="'col-md-'+colAddAluno" style="padding: 25px; background-color: #fff;border: 1px solid rgba(0, 0, 0, 0.125);border-radius: 0.25rem;box-shadow: 0 4px 70px -18px #70707057;">
          <div class="row">
            <div class="col-md-4">
              <h6 style="text-transform: uppercase;padding-top: 27px;padding-bottom: 13px;color: #697277;">Alunos</h6>
            </div>
            <div class="col-md-5" style="padding-top: 17px;" v-if="situacaoAno && this.turma_ativa">
              <va-button class="mr-2 mb-2"  color="success"  @click="alunoPainel(1,'', 1)"> {{ $t('ADD ALUNO') }}</va-button>
            </div>
            <div class="col-md-3" style="padding-top: 17px;" v-if="situacaoAno && !this.turma_ativa">
              <va-button class="mr-2 mb-2"  color="success"  @click="alunoPainel(1,'', 0)" > {{ $t('ADD ALUNO') }}</va-button>
            </div>
          </div>

          <div  style="width:100%;height:auto;"
              v-if="abrirpainel">
            <div class="col-md-12" style="background-color: rgb(229 229 229);
                                  width: 100%;
                                  padding: 20px;
                                  margin-bottom: 10px;">
              <h4 v-if="painelacoes">Adicionar Aluno</h4>
              <h4 v-else >Editar Aluno</h4>

              <p v-if="name_old != ''"><b style="color: #3a6ee3;">{{nome_old}}</b></p>


              <div class="flex md12 xs12">



                <va-select
                  class="mb-4"
                  :label="$t('Selecione os alunos')"
                  v-model="aluno"
                  multiple
                  textBy="label"
                  searchable
                  :options="alunos"
                  @update-search="buscarAluno"
                >
                  <template >
                    <div >
                    </div>

                  </template>
                </va-select>


              </div>





              <va-button v-if="painelacoes"  @click="addAluno()" color="secondary"  :disabled="disableButtonAlunoTransferido == true">
                Adicionar
              </va-button>
              <va-button v-else  @click="editarAluno()" color="secondary">
                Editar
              </va-button>
            </div>

          </div>

          <table class="table" style="background-color: #e1e8ec;">
            <thead style="display: none;">
              <tr>
                <th>Alunos</th>
                <th>acao</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="n in alunosAll" v-bind:key="n.id">
                <td>{{n.aluno.nome}}</td>
                <td>
                  <va-button  @click="abrirModalEReceberAluno(n, 1)"
                     color="#097e3300"
                      v-if="situacaoAno && turma_ativa"
                  >
                    <i class="pi pi-user-minus" style="font-size: 24px;color: #697277;"></i>
                  </va-button>
                 <va-button @click="abrirModalEReceberAluno(n, 2)"
                     color="#097e3300"
                      v-if="situacaoAno && turma_ativa"
                  >
                    <i class="pi pi-arrow-up-right" style="font-size: 24px;color: #697277;"></i>
                  </va-button>
                  <va-button  @click="alunoPainel(2,n)"
                     color="#097e3300"
                      v-if="situacaoAno && !turma_ativa"
                  >
                    <i class="pi pi-times-circle" style="font-size: 24px;color: #697277;"></i>
                  </va-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col-md-5">

        </div>

      </div>
    </div>

    <div class="form-elements">
      <div class="row">
        <va-modal v-model="abrirModalRemover"  hide-default-actions >
          <slot>
            <p><b>Deseja remover o aluno? Selecione o motivo!</b></p>
              <div class="flex md6">
                <fieldset>
                  <va-radio
                    option="option1"
                    v-model="radioSelectedOption"
                    label="Saiu da escola(Transferido)."
                  />
                  <va-radio
                    option="option2"
                    v-model="radioSelectedOption"
                    label="Saiu da escola(Evadido)."
                  />
                  <va-radio
                    option="option3"
                    v-model="radioSelectedOption"
                    label="Faleceu."
                  />
                </fieldset>
              </div>

             <va-button  color="secondary"  @click="removerAlunoMotivo">
                Realizar ação
             </va-button>
          </slot>
        </va-modal>

        <va-modal v-model="abrirModalTransferir"  hide-default-actions >
          <slot>
            <p><b>Transferência interna do aluno </b></p>
              <div>
                <div class="form-group col-md-12" >
                  <label >Selecione a turma para qual o aluno será transferido:</label>
                  <select class="form-control" v-model="alunoTurmaTransferida">
                    <option v-for="n in turmasRelacionadas" :key="n" :value="n.id" >{{n.nome}}</option>
                  </select>
                </div>
                <div class="flex" style="margin-top:15px" v-if="alunoTurmaTransferida != 0">
                  <va-button class="mr-2 mb-2" @click="realizarTranferenciaAluno()">
                    {{ $t("Realizar transferência") }}
                  </va-button>
                </div>
              </div>
          </slot>
        </va-modal>


        <va-modal v-model="abrirModalAdicionar"  hide-default-actions >
          <slot>
            <p><b>Disciplinas</b></p>
              <fieldset >
                <div>
                  <label class="form-check-label" v-for="item in disciplinas" v-bind:key="item.id" style="padding-right: 15px" >
                      <input type="checkbox" class="form-check-input" v-model="check_disciplinas.item"
                            :id="item.nome" :value="item.id"> {{item.nome}}
                  </label>
                </div>
              </fieldset>

             <va-button v-if="disciplina_tem == 0" @click="adicionarDisciplinaTurma" color="secondary" :loading="botaoLoanding == true">
                Adicionar Disciplinas
             </va-button>
             <va-button v-if="disciplina_tem == 1 && !turma_dados[0].ativa" @click="editarDisciplinaTurma" color="secondary" :loading="botaoLoanding == true">
                Editar Disciplinas
             </va-button>
          </slot>
        </va-modal>

        <va-modal v-model="showDisciplinaProfessorAddModal" hide-default-actions>
          <slot>
            <form>
              <div class="row">
                <!--edicao-->
                <div>
                  <p><b>Adicionar professor da disciplina</b></p>
                  <div class="form-group col-md-12">
                    <label >Selecione o novo professor:</label>
                    <select class="form-control"  v-model="professor.id">
                      <option v-for="n in disc.professores_relacionados" :key="n" :value="n.servidor_id" >{{n.servidor.nome}}</option>
                    </select>
                  </div>

                  <div class="flex" style="margin-top:15px" v-if="estiloTabela == 2 || (estiloTabela == 3 && !ejaEhFundamental1) && situacaoAno">
                    <va-button class="mr-2 mb-2"  @click="addDisciplinaNaoFundamentalI(info)" :loading="botaoLoanding == true">
                      {{ $t("Adicionar professor") }}
                    </va-button>
                  </div>
                  <div class="flex" style="margin-top:15px" v-else>
                    <va-button class="mr-2 mb-2"  @click="addFundIDisciplina" :loading="botaoLoanding == true">
                      {{ $t("Adicionar professor") }}
                    </va-button>
                  </div>
                </div>
              </div>
            </form>
          </slot>
        </va-modal>
        <va-modal v-model="showDisciplinaProfessorEditarModal" hide-default-actions>
          <slot>
            <form>
              <div class="row">
                <!--edicao-->
                <div>
                  <p><b>Editar professor da disciplina</b></p>
                  <div class="form-group col-md-12">
                    <label >Selecione o novo professor:</label>
                    <select class="form-control"  v-model="professor.id">
                      <option v-for="n in disc.professores_relacionados" :key="n" :value="n.servidor_id" >{{n.servidor.nome}}</option>
                    </select>
                  </div>

                  <div class="flex" style="margin-top:15px" v-if="estiloTabela == 2 || (estiloTabela == 3 && !ejaEhFundamental1) && situacaoAno">
                    <va-button class="mr-2 mb-2"  @click="editarDisciplinaNaoFundamentalI" :loading="botaoLoanding == true">
                      {{ $t("Alterar") }}
                    </va-button>
                  </div>
                  <div class="flex" style="margin-top:15px" v-if="estiloTabela == 1 || ejaEhFundamental1">
                    <va-button class="mr-2 mb-2"  @click="editarFundIDisciplina" :loading="botaoLoanding == true">
                      {{ $t("Alterar") }}
                    </va-button>
                  </div>
                </div>
              </div>
            </form>
          </slot>
        </va-modal>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Calendario } from "@/class/calendario.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { Aluno } from "@/class/alunos";
import { Professor } from "@/class/professor";

import axios from 'axios'

//import Multiselect from '@vueform/multiselect'

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{
    }
  },
  components: {
      //Multiselect,
  },
  name: 'turma-config',
  data () {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      estiloTabela:0,
      ejaEhFundamental1:0,
      ehDoTiPoCreche:false,
      disableButtonAlunoTransferido:false,
      turmasRelacionadas:[],
      alunoTurmaTransferida:0,
      alunoSelecionadoParaRemover:false,
      alunoTransferido:false,
      abrirModalRemover:false,
      abrirModalTransferir:false,
      radioSelectedOption: "opcao1",
      calendarioAtualViz: [],
      situacaoAno:1,
      horarioDeTrabalhoProfessores:[],
      botaoLoanding:false,
      showDisciplinaProfessorVisualizarModal:false,
      showDisciplinaProfessorAddModal:false,
      showDisciplinaProfessorEditarModal:false,
      abrirModalAlunos:false,
      professor_old:0,
      turmaInfo:[],
      disciplina_tem:0,
      professor_tem:0,
      disc_checadas:[],
      disciplinas:[],
      check_disciplinas:{
        item:[]
      },
      disciplinasTurma:[],
      disciplinasTurma_aux:[],
      cliq_disciplina:0,
      disc:{
        id:0,
        nome:"",
        professor:"",
        professor_id:0,
        professores_relacionados:[],
        edit_professor_id:0
      },
      abrirModalAdicionar:false,
      etapa_nome:"",
      professor:{
        id:undefined,
        disciplina_id:0,
      },
      edit:0,
      st_etapa_id:{},
      st_periodo_id:{},
      st_turma_id:{},
      st_escola_id:{},
      turma_dados:[],
      alunosAll:[],
      alunos:[],
      aluno: [],
      abrirpainel:false,
      painelacoes:true,
      id_old:0,
      nome_old:'',
      erroturmaeditar:0,
      turma_ativa:0,
      colAddProfessor:6,
      colAddAluno:6,
    }
  },
  methods:{
    async buscarAluno(event) {
      let data =  await axios.get('/alunos/v2/'+event);
      this.alunos = [];
      for (const l of data.data) {
        if(l.ok == 0){
          let nv ={
            value: l.id,
            label: l.nome,
          }
          this.alunos.push(nv);
        }
      }
    },
     retornarEdicaoTurma(){
      sessionStorage.setItem("erroturmaeditar",0);
      this.$router.push({ name: "turmasEditar", params: { id: this.st_turma_id } });
     },
     async adicionarDisciplinaTurma(){
      try {
        this.botaoLoanding = true;
        let data = await Turma.turmasDisciplina(this.st_turma_id);
        if(data.data.length > 0){
          alert("Utilize o botão Editar");
          return false;
        }

        if(this.check_disciplinas.item.length>0){
          for (let i = 0; i < this.check_disciplinas.item.length; i++) {

            let arr = {
              turma_id: parseInt(this.st_turma_id),
              disciplina_id: this.check_disciplinas.item[i]
            };
            let dt = await Turma.addDisciplina(arr);
          }
        }
        this.botaoLoanding = false;
        this.abrirModalAdicionar = false;
        this.$vaToast.init({
          message: "Disciplinas adicionadas com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
        });

      } catch (e) {
        this.$vaToast.init({
          message: "Erro ao adicionar disciplina!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
        });
      }
      this.buscaTurmaInfo();
    },

    async editarDisciplinaTurma(){
      try {
        this.botaoLoanding = true;
        let data1 = await Turma.turmasDisciplina(this.st_turma_id);
        //Verificar se ele excluiu alguma disciplina
        for (var i = 0; i < data1.data.length; i++) {
          const found = this.check_disciplinas.item.find(id => id ==  data1.data[i].disciplina_id);
          //ele desmarcou a disciplina
          if (found == undefined) {
              if (!this.turmaInfo.ativa) {
               let novo = {
                  turma_id: this.st_turma_id,
                  disciplina_id:  data1.data[i].disciplina_id,
                }
                try {
                   let data2 = await Turma.deletarDisciplinaDaTurma(novo);
                  //Buscar pelo professor para deleta-lo
                  for (var j = 0; j < this.disciplinasTurma.length; j++) {
                    if (this.disciplinasTurma[j].disciplina_id == data1.data[i].disciplina_id) {
                      if (this.disciplinasTurma[j].jatemprofessor == "Professor não Encontrado!") {
                        break;
                      }
                      let novo1 = {
                        turma_id: this.st_turma_id,
                        professor_id: this.disciplinasTurma[j].jatemprofessor.professor.id,
                        turma_disciplina_id: this.disciplinasTurma[j].jatemprofessor.turma_disciplina_id,
                      }
                      let data3 = await Turma.deletarProfessorDaDisciplina(novo1);
                      break;
                    }
                  }
                }
                catch(e) {
                  this.abrirModalAdicionar = false;
                  this.botaoLoanding = false;
                  this.$vaToast.init({
                    message: "Erro ao realizar a edição!",
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    duration: 3500,
                    fullWidth: false,
                  });
                }
              }
              else {
                this.abrirModalAdicionar = false;
                this.botaoLoanding = false;
                let data2 = await DisciplinaEscolar.obtemUm(data1.data[i].disciplina_id);
                this.$vaToast.init({
                  message: "A disciplina " + data2.data.nome + " não pode ser deletada, pois existe diário e professor ligado a ela!",
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 4000,
                  fullWidth: false,
                });
                return false;
              }
          }
        }
        //Verificar se ele adicionou alguma disciplina
        for (var i = 0; i < this.check_disciplinas.item.length; i++) {
          const found = data1.data.find(element => element.disciplina_id == this.check_disciplinas.item[i]);
          //Se a disciplina ainda nao existe na turma, add ela
          if (found == undefined) {
            //adicionar a nova disciplina na turma
            let arr = {
              turma_id: parseInt(this.st_turma_id),
              disciplina_id: this.check_disciplinas.item[i]
            };
            try {
              let dt = await Turma.addDisciplina(arr);
              /* se a Etapa for Fundamental I */
              /* Depois de acrescentar as novas disciplinas automaticamente adicionar o professor nela  */
              if((this.estiloTabela == 1 || this.ejaEhFundamental1) && this.professor.id != undefined){
                let novo = {
                  turma_id: this.st_turma_id,
                  professor_id: this.professor.id,
                  turma_disciplina_id: dt.data.id,
                }
                let data = await Turma.addProfessorTurma(novo);
              }
            }
            catch(e) {
                this.abrirModalAdicionar = false;
                this.botaoLoanding = false;
                this.$vaToast.init({
                  message: "Erro ao realizar a edição!",
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                });
              return false;
            }
          }
        }
        this.abrirModalAdicionar = false;
        this.botaoLoanding = false;
        this.buscaTurmaInfo();
        this.$vaToast.init({
          message: "Edição realizada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
        });
      } catch (e) {
        this.abrirModalAdicionar = false;
        this.botaoLoanding = false;
        this.$vaToast.init({
          message: "Erro ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3500,
          fullWidth: false,
        });
      }
    },

    async professorDaDisciplina(idDoProfessor, id, nome, professor, professores, cadastrarOuEditar){
      this.professor_old = idDoProfessor;
      this.professor.disciplina_id = id;
      this.disc.id = 0;
      this.disc.nome = "";
      this.disc.professor ="";
      this.disc.id = id;
      this.disc.nome = nome;
      if(professor == "Professor não Encontrado!"){

      }else{
        this.disc.professor = professor.nome;
        this.disc.professor_id = idDoProfessor;
      }
      //apenas para fundamental 1 e ERN
      this.disc.professores_relacionados = [];
      let auxProfessoresRelacionados = [];
      //verificar se o servidor ta vazio
      for (var i = 0; i < professores.length; i++) {
        if (professores[i].servidor != null &&  professores[i].servidor != '') {
          if (professores[i].servidor.length !== 0 && this.estiloTabela == 2 || !this.ejaEhFundamental1) {
               auxProfessoresRelacionados.push(professores[i]);
          }
          else if (professores[i].servidor.length !== 0) {
            if (this.ehDoTiPoCreche && professores[i].disciplina_id == 2){
              auxProfessoresRelacionados.push(professores[i]);
            }else if (!this.ehDoTiPoCreche && professores[i].disciplina_id == 1){
              auxProfessoresRelacionados.push(professores[i]);
            }
          }
        }
      }
      //Verificar se o professor já está cadastrado em uma turma num horário específico, apenas para fundamental 1 e ERN
      if (this.estiloTabela == 1 || this.ejaEhFundamental1) {
        for (var i = 0; i < auxProfessoresRelacionados.length; i++) {
          let novo = {
            servidor_id: auxProfessoresRelacionados[i].servidor_id,
            ano: this.calendarioAtualViz.ano,
            escola_id: this.st_escola_id
          }
          let turnoDoProfessor = await Professor.verTurnosdoProfessor(novo);
          let jaTaEmUmaTurma = 0, ehOMesmoTurno= 0;
          for (var j = 0; j < turnoDoProfessor.data.length; j++) {
            if (turnoDoProfessor.data[j].turno == this.st_turno) {
              ehOMesmoTurno = 1;

              if (turnoDoProfessor.data[j].turma != undefined) {
                jaTaEmUmaTurma = 1;
                break;
              }
            }
          }
          if (!jaTaEmUmaTurma && ehOMesmoTurno)
            this.disc.professores_relacionados.push(auxProfessoresRelacionados[i]);
        }
      }
      else {
        this.disc.professores_relacionados = auxProfessoresRelacionados;
      }
      this.cliq_disciplina = 1;
      if (cadastrarOuEditar == 1) {
         if (!this.disc.professores_relacionados.length) {


          if (window.confirm("Não existem professores relacionados à disciplina selecionada! Você deseja cadastrar algum professor para adicioná-lo à turma?")) {
            this.$router.push({ name: "servidores"});
          }
          return false;
        }
        else {
          this.showDisciplinaProfessorAddModal = true;
        }
      }
      else if (cadastrarOuEditar == 2) {
        //remover professor atual da lista de edicao
        for(var i = 0; i < this.disc.professores_relacionados.length; i++) {
            if(this.disc.professores_relacionados[i].servidor_id ==  this.professor_old) {
              this.disc.professores_relacionados.splice(i, 1);
              break;
            }
        }
        if (!this.disc.professores_relacionados.length) {
          this.$vaToast.init({
              message: "Não existem outros professores relacionados à disciplina selecionada!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning',
              duration: 3500,
              fullWidth: false,
          });
          return false;
        }
        else {
          this.showDisciplinaProfessorEditarModal = true;
        }
      }
    },
    async buscarEtapa(){
      let dt = await SegmentoEscolar.obtemUm(this.st_etapa_id);
      this.etapa_nome = dt.data.nome;
    },
    async buscarTurmaInfo() {
      let data2 = await Turma.obtemUm(this.st_turma_id);
      this.turmaInfo = data2.data;
    },
    abrirModalEReceberAluno(alunoSelecionado, removerOuTransferir) {
      this.alunoSelecionadoParaRemover = alunoSelecionado.aluno_id;
      //abrir modal de remover
      if (removerOuTransferir == 1) {
        this.abrirModalRemover = !this.abrirModalRemover;

      }
      else {
        this.buscaTurmasRelacionadas();
        //abrir modal de transferir
        this.abrirModalTransferir = !this.abrirModalTransferir;
      }
    },
    async addDisciplinaNaoFundamentalI() {
      try {
          if (this.professor.id == null || this.professor.id == '') {
            this.$vaToast.init({
              message: "Selecione algum professor!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning',
              duration: 3600,
              fullWidth: false,
            });
            return false;
          }
          this.botaoLoanding = true;
          let novo = {
            turma_id: this.st_turma_id,
            professor_id: this.professor.id,
            turma_disciplina_id: this.professor.disciplina_id,
          }
          let data = await Turma.addProfessorTurma(novo);
          this.botaoLoanding = false;
          this.showDisciplinaProfessorAddModal = false;
          this.$vaToast.init({
            message: "Adicionado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
          });
        }
        catch (e) {
           this.$vaToast.init({
            message: "Problema ao adicionar!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
          })
        }
        this.buscaTurmaInfo();
    },

    async editarDisciplinaNaoFundamentalI(){
      try {
        if (this.professor.id == null || this.professor.id == '') {
            this.$vaToast.init({
              message: "Selecione algum professor!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning',
              duration: 3600,
              fullWidth: false,
            });
            return false;
        }
        this.botaoLoanding = true;
        let novo = {
          professor_old: this.professor_old,
          turma_id: parseInt(this.st_turma_id),
          professor_id: this.professor.id,
          turma_disciplina_id: this.professor.disciplina_id,
        }
        let data = await Turma.EditarProfessorTurma (novo);
        this.botaoLoanding = false;
        this.showDisciplinaProfessorEditarModal = false;
        this.$vaToast.init({
            message: "Editado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
        });
      } catch (e) {
        console.log(e);
      }
      this.buscaTurmaInfo();
    },

    async deletarDisciplinaNaoFundamentalI(info) {
      try {
        if (confirm("Você realmente deseja retirar o professor(a) da disciplina?")) {
          let novo = {
            turma_id: this.st_turma_id,
            professor_id: info.jatemprofessor.id,
            turma_disciplina_id:  info.id,
          }
          let data = await Turma.deletarProfessorDaDisciplina(novo);
          this.$vaToast.init({
            message: "Professor retirado da disciplina com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3600,
            fullWidth: false,
          });
        }
      }
      catch(e) {
        this.$vaToast.init({
          message: "Problema ao retirar o professor da disciplina!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3600,
          fullWidth: false,
        });
      }
      this.buscaTurmaInfo();
    },

    async addFundIDisciplina(){
      try {
        if (this.professor.id == null || this.professor.id == '') {
          this.$vaToast.init({
            message: "Selecione algum professor!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color: 'warning',
            duration: 3600,
            fullWidth: false,
          });
          return false;
        }
        this.botaoLoanding = true;
        for (const el of this.disciplinasTurma) {

          let novo = {
            turma_id: this.st_turma_id,
            professor_id: this.professor.id,
            turma_disciplina_id: el.id,
          }
          let data = await Turma.addProfessorTurma(novo);
        }
        this.botaoLoanding = false;
        this.showDisciplinaProfessorAddModal = false;
        this.$vaToast.init({
          message: "Professor adicionado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          fullWidth: false,
        });
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.$vaToast.init({
          message: "Problema ao adicionado o professor",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          fullWidth: false,
        });
      }
      this.buscaTurmaInfo();
    },

    async editarFundIDisciplina(){
      try {
        if (this.professor.id == null || this.professor.id == '') {
            this.$vaToast.init({
              message: "Selecione algum professor!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'warning',
              duration: 3600,
              fullWidth: false,
            });
            return false;
        }
        this.botaoLoanding = true;
        for (const el of this.disciplinasTurma) {

          let novo = {
            professor_old: this.professor_old,
            turma_id: parseInt(this.st_turma_id),
            professor_id: this.professor.id,
            turma_disciplina_id: el.id,
          }
          let data = await Turma.EditarProfessorTurma(novo);
        }
        this.botaoLoanding = false;
        this.showDisciplinaProfessorEditarModal = false;
        this.$vaToast.init({
          message: "Professor editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          fullWidth: false,
        });
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.$vaToast.init({
          message: "Problema ao editar o professor",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          fullWidth: false,
        });
      }
      this.buscaTurmaInfo();
    },

    async deletarFundIDisciplina(){
      try {
        if (confirm("Você realmente deseja retirar o professor(a) da turma?")) {
          for (const el of this.disciplinasTurma) {

            let novo = {
              turma_id: parseInt(this.st_turma_id),
              professor_id: this.professor.id,
              turma_disciplina_id: el.id,
            }
            let data = await Turma.deletarProfessorDaDisciplina(novo);
          }
          this.$vaToast.init({
            message: "Professor retirado da turma com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            fullWidth: false,
          });
        }
     }
     catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
     }
      this.buscaTurmaInfo();
    },
    async removerAlunoMotivo() {
      try {
        let motivo = "";
        if (this.radioSelectedOption == "option1") {
          motivo = "Transferido";
        }
        if (this.radioSelectedOption == "option2") {
          motivo = "Evadido";
        }
        else if (this.radioSelectedOption == "option3") {
          motivo = "Faleceu";
        }
        let novo = {
          motivo: motivo,
          turma_id: this.turma_id,
          aluno_id: this.alunoSelecionadoParaRemover,
        }
        let data = await Aluno.RemoverAlunoTurma(novo);
        this.abrirModalRemover = !this.abrirModalRemover;
        this.$vaToast.init({
            message: "Aluno removido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.buscarTurmas();
      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao remover o aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'warning',
            fullWidth: false,
        });
      }
    },
    async buscarTurmas(){
      try {
        let data = await Turma.obtemAlunosTurma(this.st_turma_id);
        this.alunosAll = data.data;
      } catch (e) {

      }
    },
    async alunoPainel(tipo,arr, tipoAluno){
      this.alunoTransferido = tipoAluno;
      this.nome_old= '';
      if(tipo == 1){
        this.abrirpainel = true;
        this.id_old = 0;
        this.painelacoes = true;
        await this.alunosQNaoSaoDaTurma();
      }else if(tipo == 0){
        this.abrirpainel = true;
        this.id_old = 0;
        this.id_old = arr.id;
        this.nome_old = arr.nome;
        this.painelacoes = false;
        await this.alunosQNaoSaoDaTurma();
      }else{
        this.id_old = 0;
        this.id_old = arr.id;
        await this.deletarAluno();
      }

    },

    async alunosQNaoSaoDaTurma(){
      try {
        let data = await Aluno.obtemTodos2();
        this.alunos = [];
        for (const l of data.data) {
          if(l.ok == 0){
            let nv ={
              value: l.id,
              label: l.nome,
            }
            this.alunos.push(nv);
          }
        }
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
      }
    },
    async addAluno(){
      try {
        if (this.aluno.length == 0) {
          this.$vaToast.init({
            message: 'Selecione pelo menos um aluno!',
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'warning'
          });
          return false;
        }
        if (this.alunoTransferido == 0) {
          let alunosParaEnvio = [];
          for (const el of this.aluno) {
            let payload = {
              ano: this.calendarioAtualViz.ano,
              aluno_id: el.value,
              nome: el.label,
              turma_id: this.st_turma_id,
            };
            alunosParaEnvio.push(payload);
          }
          this.abrirpainel = false;
          let data = await Aluno.addAlunosTurma(alunosParaEnvio);
          this.aluno = null;
          this.buscarTurmas();
        }
        else {
          this.disableButtonAlunoTransferido = true;
          for (const el of this.aluno) {
            let payload = {
              turma_id: this.st_turma_id,
              aluno_id: el.value,
            };
            let data = await Aluno.addAlunoTransferidoDeEscola(payload);
            this.aluno = null;
          }
          this.buscarTurmas();
          this.disableButtonAlunoTransferido = false;
        }
        await this.alunosQNaoSaoDaTurma();
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
      }
    },
    async editarAluno(){
      try {
        let dt =  await Calendario.calendarioAtual();
        let payload = {
          ano: dt.data.ano,
          aluno_id: this.aluno,
          turma_id: this.st_turma_id,
          id:this.id_old,
        };
        this.abrirpainel = false;
        let data = await Aluno.editarAlunoTurma(payload);
        this.aluno = null;
        this.buscarTurmas();
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
      }
    },
    async deletarAluno(){
      try {
        let data = await Aluno.deletarAlunoTurma(this.id_old);
        this.aluno = null;
        this.buscarTurmas();
      } catch (e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a[0],
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
      }
    },
    async buscaTurmasRelacionadas() {
      let novo = {
        ano: this.calendarioAtualViz.ano,
        escola_id:this.st_escola_id,
        segmento_id: this.st_etapa_id,
      }
      let data = await Turma.showEscolaSegmentoTurma(novo);
      this.turmasRelacionadas = [];
      for (const el of data.data) {
        //nao mostra a turma que ele atualmente está
        if (el.id == this.st_turma_id)
          continue;
        this.turmasRelacionadas.push(el);
      }
    },
    async realizarTranferenciaAluno() {
      try {
        let novo = {
          turma_id: this.st_turma_id,
          turma_id_nova: this.alunoTurmaTransferida,
          aluno_id: this.alunoSelecionadoParaRemover,
        }
        let data = await Aluno.TransferenciaInternaAluno(novo);
        this.alunoTurmaTransferida = 0;
        this.alunoSelecionadoParaRemover = 0;
        this.abrirModalTransferir = !this.abrirModalTransferir;
        this.buscarTurmas();
        this.$vaToast.init({
            message: "Aluno transferido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
      }
      catch(e) {
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },
    async buscarCalendarioAtual() {
      let dt =  await Calendario.calendarioAtual();
      this.calendarioAtualViz = dt.data;
    },
    trocarTurma(){
      this.$router.push({ name: "escolasInfo" });
    },
    irPanejamento(){
      this.$router.push({ name: "planejaConfig" , params:{ etapa_id: this.st_etapa_id, periodo_id: this.st_periodo_id,turma_id: this.st_turma_id } });
    },
    irDiario(){
      this.$router.push({ name: "presencaConfigAll" , params:{ etapa_id: this.st_etapa_id, periodo_id: this.st_periodo_id,turma_id: this.st_turma_id, escola_id: this.st_escola_id } });
    },
    irNotas(){
      this.$router.push({ name: "notasAllTurma" , params:{ turma_id: this.st_turma_id} });
    },
    async buscaTurmaInfo() {
      let turma = {
          turma_id: this.st_turma_id,
          etapa_id: this.st_etapa_id,
          escola_id: this.st_escola_id,
      }
      let data = await Turma.aturmaConfi(turma);

      this.disciplinasTurma = data.data;
      this.disciplina_tem = 0;
      if (this.disciplinasTurma != 'A Turma Não possui Disciplina!') {
        this.disciplina_tem = 1;
        this.disc_checadas = [];
        for (var i = 0; i < this.disciplinasTurma.length; i++) {
          this.disc_checadas.push(this.disciplinasTurma[i].disciplina_id);
        }
        this.check_disciplinas.item = this.disc_checadas;
        if (this.estiloTabela == 1 || this.ejaEhFundamental1) {
          this.professor.id = data.data[0].jatemprofessor.id;
        }
      }
    },

  },


  async created(){
      if(this.etapa_id == null ||  this.periodo_id == null || this.turma_id == null  ||  this.escola_id == null){
        this.st_etapa_id = sessionStorage.getItem("TurmaConfg_etapa_id");
        this.st_periodo_id = sessionStorage.getItem("TurmaConfg_periodo_id");
        this.st_turma_id = sessionStorage.getItem("TurmaConfg_turma_id");
        this.st_escola_id = sessionStorage.getItem("TurmaConfg_escola_id");
        this.st_turno = sessionStorage.getItem("TurmaConfg_turno");
        this.erroturmaeditar = sessionStorage.getItem("erroturmaeditar");
        //this.$router.push({name: 'escolas'});
      }else{
        this.st_etapa_id = this.etapa_id;
        this.st_periodo_id = this.periodo_id;
        this.st_turma_id = this.turma_id;
        this.st_escola_id =this.escola_id;
        this.st_turno =this.turno;
      }

      let turma = {
        turma_id: this.st_turma_id,
        etapa_id: this.st_etapa_id,
        periodo_id: this.st_periodo_id,
        escola_id: this.st_escola_id,
      }

      let resp_turma = await Turma.aturma(turma);
      let  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;

      this.turma_dados = resp_turma.data;
      this.turma_ativa = this.turma_dados[0].ativa;

      let data2 = await SegmentoEscolar.obtemUm(this.st_etapa_id);
      this.estiloTabela = data2.data.tipo;

      if (data2.data.tipo == 3) {
        let data3 = await SeriesEscolar.obtemUm(this.st_periodo_id);
        if (data3.data.tipo == 1) {
           this.ejaEhFundamental1 = 1;
        }
        else {
           this.ejaEhFundamental1 = 0;
        }
      }

      if (data2.data.tipo == 1) {
        this.ejaEhFundamental1 = 1;
      }
  },
  async beforeMount(){
    try {
      if(this.tipoDeAcao == 1){
         this.colAddProfessor = 12;
      } else{
         this.colAddProfessor = 6
      }
      if (this.tipoDeAcao == 2) {
        this.colAddAluno = 12;
      }
      else {
        this.colAddAluno = 6;
      }
      let data2;
      //verificar se eh do tipo creche
      if (this.st_periodo_id != null)
        data2 = await SeriesEscolar.obtemUm(this.st_periodo_id);
      //3 eh do tipo creche
      if (data2.data.tipo == 3) {
          this.ehDoTiPoCreche = 1;
          //verificar se a disciplina creche ja foi adicionada
          let data3 = await Turma.turmasDisciplina(this.st_turma_id);
          if (data3.data.length == 0) {
            let arr = {
              turma_id: parseInt(this.st_turma_id),
              disciplina_id: 2,
            };
            let dt = await Turma.addDisciplina(arr);
          }
      }
      else {
        let data = await DisciplinaEscolar.obtemTodosSomenteTrue();
        for (const el of data.data) {
          if(el.id != 1 && el.id != 2){
            let novo = {
              id: el.id,
              nome: el.nome,
              status: el.status
            }
            this.disciplinas.push(novo);
          }
        }
      }
      this.buscarCalendarioAtual();
      this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
      await this.buscaTurmaInfo();
      await this.buscarTurmas();
    } catch (e) {
      console.log(e)
    }
  }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
<style >
.cabecario-turma{
  background-color:#9ec403;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
.turmaAtiva{
  background-color:#3d9209;
  color: #fff;
  margin-right: 10px;
  padding: 8px;
  border-radius: 5px;
  text-transform: uppercase;
}
.table > :not(:first-child) {
    border-top: 0px solid currentColor;
}
tr{
  border-color: #fff;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
